<template>
<div class="center">
    <div id="app">
<a href="#" @click="setLocale('en')">RISSIAANANN</a>
<a href="#" @click="setLocale('ru')">RISSIAANANN</a>
  <p style="text-align: center;">{{ $t("message.hello") }}</p>
  <p style="text-align: center;">{{ $t("message.anal") }}</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  <p style="text-align: center;">wait</p>
  </div>
</div>
  <p style="text-align: center;">waitmore</p>
<div class="text-block">
    {{ $t('message.main.aboutmetext') }}
</div>
</template>

<style>
.text-block {
    white-space:pre-line;
}
</style>